<i18n src="@/locales/article83.json" />

<template>
  <div class="fixed-background">
    <header class="columns is-centered">
      <div class="column is-9">
        <h1 class="has-background-primary-light-opacity mt-2">
          {{ $t('h1_title') }}
        </h1>
        <img
          src="@/assets/img/articles/article83/fabrication-prothese-dentaire.jpg"
          alt=""
        >
      </div>
    </header>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message')" />
    </ImanTestimonialMessage>
    <ImanTestimonialArticle :title="$t('article1_title')">
      <div class="content">
        <p class="mt-3">
          {{
            $t('article1_p1')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article2_title')">
      <div class="content">
        <p class="mt-3">
          {{
            $t('article2_p1')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video1" />
        </div>
      </div>
    </section>
    <ImanTestimonialArticle :title="$t('article3_title')">
      <div class="content">
        <p class="mt-3">
          {{
            $t('article3_p1')
          }}
        </p>
        <p>
          {{
            $t('article3_p2')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video2" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: "Article83",
  mixins: [metaMixin],
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      video1: {
        src: 'https://www.youtube.com/embed/eyISrdPGqyo'
      },
      video2: {
        src: 'https://www.youtube.com/embed/STne24WFvK4'
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/MINIATURE_8.3.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
h1 {
  font-family: 'Comfortaa', cursive;
  color: $white;
  font-size: 1.7rem;
  font-weight: lighter;
  line-height: $title-line-height;
  padding: 0.5rem;
}
</style>
